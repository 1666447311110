var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('driverProfile.pageTitle'),"breadcrumb":[
      { text: _vm.$t('home'), href: '/' },
      {
        text: _vm.$t('driverProfile.pageTitle'),
        active: true,
      } ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('div',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t("addNew"))+" ")])],1),_c('div',{staticClass:"tw-space-x-3"},[_c('a-radio-group',{on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.isInternalDriver),callback:function ($$v) {_vm.isInternalDriver=$$v},expression:"isInternalDriver"}},[_c('a-radio-button',{attrs:{"value":1}},[_vm._v(" "+_vm._s(_vm.$t("driverProfile.internal"))+" ")]),_c('a-radio-button',{attrs:{"value":0}},[_vm._v(" "+_vm._s(_vm.$t("driverProfile.external"))+" ")])],1),_c('a-radio-group',{on:{"change":function($event){return _vm.fetchData()}},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('a-radio-button',{attrs:{"value":1}},[_vm._v(" "+_vm._s(_vm.$t("driverProfile.active"))+" ")]),_c('a-radio-button',{attrs:{"value":0}},[_vm._v(" "+_vm._s(_vm.$t("driverProfile.inactive"))+" ")])],1),_c('a-tooltip',{attrs:{"title":_vm.$t('driverProfile.search')}},[_c('a-input-search',{staticStyle:{"width":"200px"},attrs:{"placeholder":_vm.$t('driverProfile.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)])]),_c('div',{staticClass:"tw-overflow-x-scroll"},[_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var record = ref.record;
return [_c('td',[_vm._v(_vm._s(record.driver_code))]),_c('td',[_vm._v(_vm._s(record.driver_name))]),_c('td',{staticClass:"tw-text-center"},[_vm._v(_vm._s(record.sex))]),_c('td',[_vm._v(_vm._s(record.dob))]),_c('td',[_vm._v(" "+_vm._s(record.phone1)),_c('br'),_vm._v(_vm._s(record.phone2)),_c('br'),_vm._v(_vm._s(record.phone3)+" ")]),_c('td',[_vm._v(" Start: "+_vm._s(record.contract_start_date)),_c('br'),_vm._v("End: "+_vm._s(record.contract_end_date)+" ")]),_c('td',[_vm._v(_vm._s(record.vehicle_types))]),_c('td',[_vm._v(_vm._s(record.plat_number))]),_c('td',[_vm._v(_vm._s(record.agency_name))]),_c('td',{staticClass:"tw-text-center"},[(record.is_internal_driver)?_c('span',{staticClass:"badge bg-primary"},[_vm._v("Yes")]):_c('span',{staticClass:"badge bg-secondary"},[_vm._v("No")])]),_c('td',{staticClass:"tw-text-center"},[(record.is_active)?_c('span',{staticClass:"badge bg-primary"},[_vm._v("Yes")]):_c('span',{staticClass:"badge bg-secondary"},[_vm._v("No")])]),_c('td',{staticClass:"text-center tw-space-x-3"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit(record)}}},[_c('i',{staticClass:"fas fa-edit"})])])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }